<template>
  <div v-if="loading" class="loader-wrapper">
    <app-loader/>
  </div>
  <div v-else>
    <section ref="hero" id="hero" class="hero">
      <div class="hero__content container">
        <h1>
          Vetomol NATÜRLICH. DAS BESTE
        </h1>
        <p>Wir von VETOMOL bieten dir den <strong>Daily Veggie Shot</strong> für deinen Lieblingsvierbeiner. Hierfür
          haben
          wir uns auf 8 regionale Super-Pflanzen konzentriert, die die tägliche Fütterung sinnvoll ergänzen können.</p>

        <app-button @click="scrollToSection($refs.products)" rounded>
          Mehr
        </app-button>
      </div>
      <div class="hero__img">
        <picture>
          <source srcSet="/img/hero-img.avif" type="image/avif"/>
          <img src="/img/hero-img.png" alt="logo" class="desktop-img"/>
        </picture>
        <!--        <img class="desktop-img" src="@/assets/img/hero-img.png" alt="logo">-->
        <picture>
          <source srcSet="/img/hero-mobile.avif" type="image/avif"/>
          <img class="mobile-img" src="/img/hero-mobile.png" alt="logo">
        </picture>
      </div>

    </section>
    <section ref="products" id="products" class="products">
      <h2 class="section-title">
        Daily Veggie Shot
      </h2>
      <!--    product details -->
      <div class="product container">
        <div class="product__img">
          <picture>
            <source srcset="/img/product-packing-pair-with-vegetables-2x.avif 2x" type="image/avif">
            <source srcset="/img/product-packing-pair-with-vegetables.avif" type="image/avif">
            <source srcset="/img/product-packing-pair-with-vegetables-2x.png 2x">
            <img src="/img/product-packing-pair-with-vegetables.png" alt="img">
          </picture>
          <div class="btn-wrap">
            <app-button
              @click="addProductToCart"
              rounded
              icon
            >
              Dem Warenkorb hinzufügen
            </app-button>
          </div>
          <!-- price block-->
          <div class="product__price-block">
            {{ product?.presets[0]?.price }}€
          </div>
        </div>
        <div class="product__description">
          <div class="top-block">
            <h3>Wo natürlich drauf steht, sollte auch natürlich drin sein. Deshalb verzichten wir auf künstliche Zusätze
              und verwenden nur natürliche Rohstoffe</h3>
          </div>
          <div class="bottom-block">
            <div class="bottom-block__inner">
              <div>
                <p>Da uns Umweltschutz und Nachhaltigkeit sehr am Herzen liegen, sehen wir keinen Mehrwert darin,
                  Produkte
                  um die halbe Welt zu schippern oder diese unter für uns unkontrollierbaren Bedingungen anbauen zu
                  lassen.</p>
                <p>Wir wollen die Dinge nicht komplizierter machen als sie sind. Deshalb orientieren wir uns am
                  heimischen
                  Garten, der in seiner Vielfalt alles Notwendige zu bieten hat.</p>
              </div>
              <div class="btn-wrap">
                <app-button
                  @click="addProductToCart"
                  rounded
                  icon
                >
                  Dem Warenkorb hinzufügen
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    END product details -->

    </section>
    <div class="container">
      <section ref="ingredients" id="ingredients" class="ingredients">
        <h2 class="section-title">
          Zutaten
        </h2>

        <ingredients-slider class="ingredients__slider"/>

      </section>

      <section ref="recipe" id="recipe" class="recipe">
        <h2 class="section-title">
          Rezept
        </h2>
        <div class="recipe__inner">
          <div class="recipe__img">
            <picture>
              <source srcset="/img/product-packing-with-vegetables-2x.avif 2x" type="image/avif">
              <source srcset="/img/product-packing-with-vegetables.avif" type="image/avif">
              <source srcset="/img/product-packing-with-vegetables-2x.png 2x">
              <img src="/img/product-packing-with-vegetables.png" alt="product">
            </picture>
          </div>
          <div class="recipe__ingredient">
            <app-recipe-block @addToCart="addProductToCart"/>
            <!--            <div class="btn-wrap">-->
            <!--              <app-button-->
            <!--                rounded-->
            <!--                icon-->
            <!--                @click="addProductToCart"-->
            <!--                class="recipe-button"-->
            <!--              >-->
            <!--                Dem Warenkorb hinzufügen-->
            <!--              </app-button>-->
            <!--              &lt;!&ndash;            <a @click.prevent href="" class="detail-link">Detail <img src="@/assets/icon/arrow-right.svg"-->
            <!--                            alt="arrow-left"></a>&ndash;&gt;-->
            <!--            </div>-->
          </div>
        </div>
      </section>

    </div>
    <div v-show="isPawMenuVisible" class="paw-menu">
      <div
        v-for="item in pawMenu"
        :key="item.id"
        @click="scrollToSection($refs[item.id])"
        :class="['paw-menu__item', {'paw-menu__active': item.isActive}]"
      >
        <span>{{ item.name }}</span>
        <div v-show="!item.isActive" class="paw-menu__marker"></div>
        <img v-show="item.isActive" src="@/assets/icon/marker-paw.svg" alt="menu-marker">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount, nextTick, watch } from 'vue';
import { useCartStore } from '@/stores/cart';
import { getData } from '@/use/api';
import AppButton from '@/components/UI/AppButton';
import IngredientsSlider from '@/components/sliders/IngredientsSlider';
import AppLoader from '@/components/UI/AppLoader.vue';
import AppRecipeBlock from '@/components/main-page/AppRecipeBlock.vue';

const cartStore = useCartStore();

const loading = ref(true);

const product = ref('');

const addProductToCart = () => {
  cartStore.addProductToCart(product.value);
  cartStore.openCloseCartModal();
};

//Sections
const hero = ref(null);
const products = ref(null);
const ingredients = ref(null);
const recipe = ref(null);

const isPawMenuVisible = ref(false);

const checkTopOffset = () => {
  isPawMenuVisible.value = window.innerHeight - 50 < window.scrollY;
};

const pawMenu = ref([
  {
    id: 'hero',
    isActive: true,
    name: 'Start',
    elemValue: hero,
  },
  {
    id: 'products',
    isActive: false,
    name: 'Daily Veggie Shot',
    elemValue: products,
  },
  {
    id: 'ingredients',
    isActive: false,
    name: 'Zutaten',
    elemValue: ingredients,
  },
  {
    id: 'recipe',
    isActive: false,
    name: 'Inhalt',
    elemValue: recipe,
  },
]);

const observer = new IntersectionObserver(
  entries => {
    entries.forEach(entrie => {
      if (entrie.isIntersecting) {
        pawMenu.value.forEach(item =>
          item.id === entrie.target.id ? item.isActive = true : item.isActive = false,
        );
      }
    });
  },
  {
    threshold: 0.5,
  },
);

const scrollToSection = (section) => {
  if (section.id === 'hero') {
    window.scrollTo({top: 0, behavior: 'smooth'});
  } else {
    section.scrollIntoView({behavior: 'smooth'});
  }
};


onBeforeMount(async () => {
  try {
    const response = await getData('/public/products/1');
    if (!response.error) product.value = response;
    window.addEventListener('scroll', checkTopOffset);
  } finally {
    loading.value = false;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', checkTopOffset);
});

watch(loading, () => {
  if (loading.value === false) {
    nextTick(() => {
      pawMenu.value.forEach((elem) => observer.observe(elem.elemValue));
    });
  }
});


</script>

<style lang="scss" scoped>
.product {
  height: 100%;
  display: flex;

  &__description {
    width: 58%;
    padding: 11.458vw 0 0 60px;
    display: flex;
    flex-direction: column;

    .top-block {
      h3 {
        font-size: 2.8rem;
        line-height: 149.4%;
        color: #000000;
        font-weight: 400;
        margin-bottom: 23px;
      }

      p {
        max-width: 496px;
      }
    }

    .bottom-block {
      flex: auto;
      background: url('@/assets/img/paws.png') no-repeat bottom right;

      &__inner {
        padding-top: 12px;

        div {
          width: 100%;
        }
      }

      .btn-wrap {
        padding-top: 15px;
        margin-bottom: 66px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: #434343;
      padding-bottom: 8px;
    }
  }

  &__img {
    position: relative;
    margin-top: -99px;
    width: 42%;
    padding: 0 20px;


    img {
      max-width: 100%;
      height: auto;
    }

    .btn-wrap {
      display: none;
    }
  }

  &__price-block {
    position: absolute;
    bottom: 50px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 24px;
    width: 179px;
    height: 186px;
    background: url('@/assets/img/price-bg.svg') no-repeat center center /contain;
    color: #fff;
    padding-right: 15px;
    padding-top: 10px;
  }

  @media screen and (max-width: 1300px) {
    justify-content: space-around;

    &__img {
      width: 44%;
      margin-top: 0;
    }

    &__description {
      width: 40%;

      .bottom-block {
        background: none;

        &__inner {
          div {
            width: 100%;
            max-width: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;

    &__img {
      order: 1;
      width: 70%;
      margin: 0 auto;
      text-align: center;

      .btn-wrap {
        z-index: 1;
        display: block;
        padding: 20px;
        text-align: center;
        margin-right: 0;
      }
    }
    &__description {
      width: 100%;
      padding: 0;

      .btn-wrap {
        display: none;
      }

      .bottom-block {
        &__inner {
          padding-right: 0;
        }
      }
    }

    &__price-block {
      bottom: 5vh;
      left: 0;
    }
  }

  @media screen and (max-width: 767px) {
    &__description {
      .top-block {
        h3 {
          font-size: 2.2rem;
          line-height: 1.5;
        }
      }

      .btn-wrap {
        display: none;
      }

      p {
        font-size: 1.4rem;
      }
    }
    &__img {

      .btn-wrap {
        display: block;
        padding: 20px;
      }
    }
  }

  @media screen and (max-width: 719px) {
    &__img {
      width: 100%;
    }
    &__price-block {
      width: 100px;
      font-size: 24px;
      padding-right: 10px;
      padding-top: 5px;
    }
  }
}

.paw-menu {
  position: fixed;
  top: 150px;
  left: 50px;
  font-size: 2rem;
  z-index: 9;

  &__item {
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 126px;
    transform: rotate(-90deg);
    box-sizing: border-box;

    span {
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      color: #001219;
      transform: scale(0);
      transition: ease-in-out 0.3s;
    }
  }

  &__active {
    span {
      transform: scale(1);
    }
  }

  &__marker {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 1px solid #192314;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1800px) {
    left: -40px;
  }

  @media screen and (max-width: 1500px) {
    display: none;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.hero {
  position: relative;

  &__img {
    background: url(@/assets/img/hero-bg.svg) bottom / 100% auto no-repeat;
    margin-top: -320px;

    img {
      max-width: 100%;
      width: 100%;

      &.mobile-img {
        display: none;
      }
    }
  }

  &__content {
    text-align: center;
    position: relative;
    padding-top: 33px;

    h1 {
      font-family: 'Covered By Your Grace', cursive;
      font-size: 8rem;
      text-transform: uppercase;
      letter-spacing: 0.01em;
      font-weight: 400;
      margin-bottom: 16px;
    }

    p {
      font-size: 2.2rem;
      line-height: 2.9rem;
      color: #969494;
      width: 59%;
      margin: 0 auto 37px;
    }

    button {
      text-transform: uppercase;
      font-size: 1.8rem;
    }
  }

  @media screen and (max-width: 1800px) {
    &__img {
      margin-top: -233px;
    }
  }

  @media screen and (max-width: 1200px) {
    &__img {
      margin-top: -185px;
    }
  }

  @media screen and (max-width: 992px) {
    &__img {
      margin-top: -143px;
    }

    &__content {
      font-size: 6rem;
    }
  }

  @media screen and (max-width: 767px) {
    &__content {
      h1 {
        font-size: 32px;
        line-height: 43px;
        margin-bottom: 10px;
        margin-top: 16px;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        width: 100%;
        margin: 0;
      }

      button {
        min-width: 150px;
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        height: 38px;
        display: block;
        margin: 17px auto 0;
      }
    }
    &__img {
      background: none;
      margin-top: -15px;

      img {
        &.desktop-img {
          display: none;
        }

        &.mobile-img {
          display: block;
        }
      }
    }
  }
}

.products {
  background: url(@/assets/img/paws.png) no-repeat top 148px right 8.333vw;
  padding-top: 6.823vw;
  position: relative;
  background-size: 11.406vw auto;

  .section-title {
    margin: 0;
  }

  .swiper {
    margin-top: 8.438vw;
  }

  @media screen and (max-width: 992px) {
    background: none;
  }
  @media screen and (max-width: 767px) {
    background: none;
    padding-top: 53px;
    .swiper {
      padding-bottom: 54px;
    }
  }
}


.ingredients {
  margin-bottom: 27px;
  padding-top: 5.469vw;

  .section-title {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 43px;
    .section-title {
      margin-bottom: 20px;
    }
  }
}

.recipe {
  margin-top: -20px;
  padding-top: 55px;
  background: url(@/assets/img/paws-alt.svg) no-repeat top -4px right -63px;

  .btn-wrap {
    width: 100%;
  }

  .section-title {
    margin-bottom: 6.146vw;
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__img {
    width: 50%;
    padding-left: 39px;
  }

  &__ingredient {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 87px;
    padding-right: 20px;
    padding-left: 20px;

    .item {
      display: flex;
      width: 50%;
      margin-bottom: 33px;

      &__info {
        padding-left: 19px;

        h6 {
          font-weight: 400;
          font-size: 2.8rem;
          letter-spacing: 0.02em;
          color: rgba(0, 0, 0, 0.8);
          margin: 22px 0 9px;
        }

        p {
          font-size: 16px;
          color: #C1C1C1;
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1279px) {
    &__img {
      width: 40%;
      padding: 0 20px;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    &__ingredient {
      width: 60%;
    }
  }

  @media screen and (max-width: 1120px) {
    &__img {
      padding-left: 0;
    }
    &__ingredient {
      .item {
        &__info {
          h6 {
            font-size: 2.3rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    background: none;
    &__inner {
      flex-wrap: wrap;
    }
    &__img {
      text-align: center;
      box-sizing: border-box;
    }
    &__img, &__ingredient {
      width: 100%;
      margin-left: 0;
    }
    &__ingredient {
      .item {
        &__info {
          h6 {
            font-size: 2.8rem;
          }
        }
      }

      .btn-wrap {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 0;
    margin-top: 0;
    .section-title {
      margin-top: 0;
    }
    &__img {
      background-size: auto 100%;
      padding: 59px 0 0;
      overflow-x: hidden;
    }
    &__ingredient {
      padding-top: 46px;
      padding-left: 0;
      padding-right: 0;
    }
    .item {
      box-sizing: border-box;

      &:nth-child(even) {
        padding-left: 10px;
      }

      &__info {
        padding-left: 15px;

        h6 {
          margin-top: 3px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    &__ingredient {
      .item {
        &__img {
          border-left: 4px solid #16582D;

          img {
            display: none;
          }
        }

        &__info {
          h6 {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}

.loader-wrapper {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>