<template>
  <div :class="['page', pageClasses]">
    <the-header/>
    <main>
      <router-view/>
    </main>
    <the-footer/>
    <cart-modal
      v-if="cartStore['cartModalIsOpened']"
      @closeModal="cartStore['openCloseCartModal']"
    >
      <cart-body/>
    </cart-modal>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useCartStore } from '@/stores/cart';
import TheHeader from '@/components/TheHeader';
import TheFooter from '@/components/TheFooter';
import CartModal from '@/components/cart/CartModal';
import CartBody from '@/components/cart/CartBody';

const route = useRoute();
const cartStore = useCartStore();

const pageClasses = computed(() => ({
  'is-checkout-page': route.name === 'checkout',
}));
</script>

<style scoped lang="scss">
/*.page {
  min-height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}*/

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

</style>