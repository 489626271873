<template>

  <component :is="BaseLayout">
    <router-view/>
  </component>

</template>

<script setup>
import {onMounted} from 'vue';
import BaseLayout from '@/layouts/BaseLayout';
import {useUserStore} from '@/stores/user';
import {useRoute, useRouter} from 'vue-router';
import {getCookie, setCookie} from '@/use/cookies';
import {useOrderStore} from '@/stores/order';

const router = useRouter();
const userStore = useUserStore();
const orderStore = useOrderStore();

const route = useRoute();
const getTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const user = urlParams.get('user');
  if (user && user !== '') localStorage.setItem('token', user);
};

const idList = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'fbclid',
  'gclid',
  'sid',
  'sid2',
  'sid3',
  'subid',
  'subid2',
  'subid3',
];

let orderObject;

if (getCookie('orderObject')) {
  orderObject = JSON.parse(getCookie('orderObject'));
} else {
  orderObject = {};
}

const getParamsFormUrl = paramName => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get(paramName);
  if (paramValue) orderObject[paramName] = paramValue;
};

onMounted(async () => {
  await getTokenFromUrl();
  const token = localStorage.getItem('token');
  if (token && token !== '') {
    await userStore.getUserData();
    const orderCount = await orderStore.getOrderCount();
    if (orderCount === 0) orderStore.setDiscount(5);
    if (orderCount > 0 && userStore.activated === false) userStore.clearUserData();
  }

  idList.forEach(param => {
    getParamsFormUrl(param);
  });

  if (Object.entries(orderObject).length !== 0) setCookie('orderObject', JSON.stringify(orderObject), 7);

  if (route.name === 'home') {
    await router.push('/');
  }
});

</script>