<template>
  <button title="Gehen Sie zum Benutzerprofil" class="profile-button">
    <a :href="url">
      <span v-if="userStore.hasAvatar" class="profile-button__img-container">
        <img :src="userStore.userAvatarPath" alt="Benutzer-Avatar" class="profile-button__img">
      </span>
      <svg v-else width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.377 6.8125C20.377 10.0227 17.7746 12.625 14.5645 12.625C11.3543 12.625 8.75195 10.0227 8.75195 6.8125C8.75195 3.60234 11.3543 1 14.5645 1C17.7746 1 20.377 3.60234 20.377 6.8125Z"
          stroke="currentColor"
          stroke-width="2"/>
        <path d="M1 32V25.3329C1 23.2462 2.19444 21.3384 4.14879 20.607C6.7851 19.6203 11.23 18.4375 14.5625 18.4375C17.9653 18.4375 21.7412 19.6707 24.718 20.6691C26.8008 21.3676 28.125 23.3518 28.125 25.5486V32"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"/>
      </svg>
    </a>
  </button>
</template>

<script setup>
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();

const url = process.env.VUE_APP_CLIENT_BASE_URL;

</script>

<style lang="scss" scoped>
.profile-button {
  position: relative;
  transition: all .35s;

  &:hover {
    background-color: #126B30;

    a {
      color: #fff;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
  }

  &__img-container {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    overflow: hidden;
    position: relative;
    border: 2px solid #fff;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>