<template>
  <div class="item__body">
    <div class="item__image">
      <img :src="imageUrl" alt="product" class="item__image">
    </div>
    <div class="item__info">
      <div class="item__product">
        <p class="item__title">
          {{ props.product.name }} / 250g
        </p>
        <p class="item__price">
          {{ priceTotal.toFixed(2) }} €
        </p>
      </div>
      <div>
        <div class="item__amount">
          <button @click="quantity--" :disabled="minusButtonDisabled">
            <img src="@/assets/icon/minus.svg" alt="minus">
          </button>
          <input type="number" minlength="1" v-model="quantity">
          <button @click="quantity++">
            <img src="@/assets/icon/plus.svg" alt="plus">
          </button>
        </div>
        <button v-if="showDeleteButton" @click="deleteProductFromCart" class="item__delete-btn">Löschen</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, watch} from 'vue';
import {useCartStore} from '@/stores/cart';
import {useRoute} from "vue-router";

const route = useRoute();
const cartStore = useCartStore();

const props = defineProps({
  product: {
    type: Object,
  },
  index: {
    type: Number,
  },
});

const productPrice = computed(() => {
  const preset = props.product.presets[0];
  if (cartStore.subscription === 0) return preset.price;
  return preset[`price${cartStore.subscription}`];
});
const quantity = ref(props.product.quantity);
const priceTotal = computed(() => quantity.value * productPrice.value);
const imageUrl = computed(() => cartStore.productImageUrl(props.index));

const minusButtonDisabled = computed(() => quantity.value <= 1);

watch(quantity, () => {
  cartStore.setProductQuantity(props.index, quantity.value);
});

const deleteProductFromCart = () => {
  cartStore.deleteProductFromCart(props.index);
};

const showDeleteButton = computed(() => route.name === 'home' || route.name === 'veggie-shot');
</script>

<style lang="scss" scoped>
.item {
  &__body {
    display: flex;
    padding-bottom: 43px;
  }

  &__info {
    flex: auto;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__product {
    display: flex;
    gap: 5px;
    @media screen and (max-width: 415px) {
      flex-direction: column;
    }
  }

  &__image {
    margin-right: 25px;
    @media screen and (max-width: 450px) {
      margin-right: 10px;
    }

    img {
      padding-right: 5px;
      max-height: 103px;
      max-width: 114px;
      width: auto;
    }
  }

  &__title {
    font-size: 18px;
    color: #060606;
    flex: 2;
    letter-spacing: 0.04em;
    margin: 9px 0 18px;
    @media screen and (max-width: 415px) {
      font-size: 16px;
      margin: 0;
    }
  }

  &__price {
    font-weight: 700;
    font-size: 22px;
    color: #333333;
    margin: 9px 0 18px;
    @media screen and (max-width: 374px) {
      font-size: 18px;
    }
  }

  &__amount {
    font-weight: 600;
    font-size: 20px;
    color: #060606;
    display: inline-flex;
    align-items: center;

    button {
      background: none;
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:disabled {
        opacity: .45;
      }
    }

    input {
      width: 40px;
      border: none;
      text-align: center;
      font-weight: 700;
      font-size: 22px;
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  &__delete-btn {
    font-size: 12px;
    color: #EB001B;
    background: none;
    border: none;
    text-decoration: underline;
    letter-spacing: 0.04em;
  }
}

</style>