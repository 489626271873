const ERROR_MESSAGES = {
  401: 'Invalid login or password',
};

export default function errorText(error) {
  return {
    error: ERROR_MESSAGES[error.response.status]
      ? ERROR_MESSAGES[error.response.status]
      : error.response.data.error,
    errorStatus: error.response.status,
  };
}
