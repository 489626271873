<template>
  <div v-if="cartStore.cart.length" class="item">
    <div v-if="cartStore.isSubscriptionActive" class="subscription">
      <div class="subscription__header">
        <h2 class="subscription__title">Wähle deine Abo Laufzeit</h2>
        <button
            v-if="cartStore.subscription > 0"
            @click="cartStore.setSubscriptionPeriod(0)"
            class="subscription__clear-button">Abbrechen
        </button>
      </div>
      <div class="subscription__buttons">
        <button
            @click="cartStore.updateSubscriptionPeriod"
            :data-months="3"
            :class="{'subscription__button_active': cartStore.subscription === 3}"
            class="subscription__button">3 Monate - 5%
        </button>
        <button
            @click="cartStore.updateSubscriptionPeriod"
            :data-months="6"
            :class="{'subscription__button_active': cartStore.subscription === 6}"
            class="subscription__button">6 Monate - 10%
        </button>
        <button
            @click="cartStore.updateSubscriptionPeriod"
            :data-months="12"
            :class="{'subscription__button_active': cartStore.subscription === 12}"
            class="subscription__button">12 Monate - 15%
        </button>
      </div>
    </div>
    <cart-item
        v-for="(product, index) in cartStore['cart']"
        :key="product.id"
        :product="product"
        :index="index"
    />
    <div class="item__footer">
      <div class="item__footer-item">
        <p>Lieferkosten:</p>
        <p>Kostenfrei</p>
      </div>
      <div v-if="cartStore.subscription" class="item__footer-item">
        <p>Anzahl der Pakete pro Monat:</p>
        <p>{{ cartStore.numberOfProductsInTheCart }}</p>
      </div>
      <div v-if="orderStore.hasDiscount" class="item__footer-item">
        <p>Rabatt:</p>
        <p>{{ orderStore.discount }} €</p>
      </div>
      <div class="item__footer-item">
        <p>Summe:</p>
        <p><small v-if="cartStore.subscription">Du sparst {{ percent }}% <s>{{ cartStore.fullSum }} €</s></small>
          {{ cartStore['subtotal'] }} €</p>
      </div>

      <div v-if="!cartStore.isSubscriptionActive" class="bottom-subscription">
        <p class="bottom-subscription__text">Spare bis zu 15% mit einem Abo</p>
        <div class="bottom-subscription__buttons">
          <button
              @click="cartStore.updateSubscriptionPeriod"
              :data-months="3"
              :class="{'bottom-subscription__button_active': cartStore.subscription === 3}"
              class="bottom-subscription__button">3 Monate - 5%
          </button>
          <button
              @click="cartStore.updateSubscriptionPeriod"
              :data-months="6"
              :class="{'bottom-subscription__button_active': cartStore.subscription === 6}"
              class="bottom-subscription__button">6 Monate - 10%
          </button>
          <button
              @click="cartStore.updateSubscriptionPeriod"
              :data-months="12"
              :class="{'bottom-subscription__button_active': cartStore.subscription === 12}"
              class="bottom-subscription__button">12 Monate - 15%
          </button>
          <button
              @click="cartStore.setSubscriptionPeriod(0)"
              class="bottom-subscription__button"
          >
            Abbrechen
          </button>
        </div>
      </div>

      <app-button
          class="submit-button"
          v-if="!props.isCheckoutPage && cartStore['numberOfProductsInTheCart']"
          @click="goToCheckoutPage"
          rounded
          id="cart-submit-button"
      >
        Zur Kasse gehen
      </app-button>

    </div>
  </div>
  <div v-else class="empty-cart">
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M25 43.75C35.3553 43.75 43.75 35.3553 43.75 25C43.75 14.6447 35.3553 6.25 25 6.25C14.6447 6.25 6.25 14.6447 6.25 25C6.25 35.3553 14.6447 43.75 25 43.75Z"
          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M25 33.333H25.0208V33.3538H25V33.333Z" stroke="currentColor" stroke-width="2.25"
            stroke-linejoin="round"/>
      <path d="M25 25.0003V16.667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>

    <span>Ihr Warenkorb ist leer</span>
  </div>
</template>

<script setup>
import AppButton from '@/components/UI/AppButton';
import {useRouter} from 'vue-router';
import {useCartStore} from '@/stores/cart';
import CartItem from '@/components/cart/CartItem';
import {useOrderStore} from '@/stores/order';
import {computed} from "vue";

const router = useRouter();
const cartStore = useCartStore();
const orderStore = useOrderStore();

const props = defineProps({
  isCheckoutPage: {
    type: Boolean,
    default: false,
  },
});

const goToCheckoutPage = () => {
  router.push({name: 'checkout'});
  cartStore.openCloseCartModal();
};

const percent = computed(() => {
  if (cartStore.subscription === 3) return 5;
  if (cartStore.subscription === 6) return 10;
  if (cartStore.subscription === 12) return 15;
  return 0;
});
</script>

<style scoped lang="scss">
.item {

  &__footer {
    padding-top: 16px;

    button.submit-button {
      height: 75px;
      width: 100%;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.04em;
      margin-top: 6px;
    }
  }

  &__footer-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #000;

    p {
      font-size: 18px;
      color: #000000;
      @media screen and (max-width: 374px) {
        font-size: 16px;
      }

      &:last-child {
        font-weight: 700;
        font-size: 22px;
        color: #333333;
        @media screen and (max-width: 374px) {
          font-size: 18px;
        }
      }
    }

    s {
      color: #777;
    }

    small {
      padding-right: 5px;
      font-weight: 400;
      font-size: 1.4rem;
      @media screen and (max-width: 374px) {
        font-size: 1.2rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .item__title, .item__price, .item__footer-item p {
    font-size: 16px;
  }
  .item__footer {
    button.submit-button {
      height: 51px;
      font-size: 16px;
    }
  }
}

.subscription {
  font-size: 1.6rem;
  margin-bottom: 50px;

  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 399px) {
    font-size: 1.2rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__clear-button {
    all: unset;
    align-self: center;
    font-size: 1.2rem;
    padding: 5px 10px;
    border-radius: 50px;
    background-color: #F7FAF8;
    cursor: pointer;
    transition: background-color 250ms;

    &:hover {
      background-color: darken(#F7FAF8, 5%);
    }
  }

  &__title {
    text-align: left;
  }

  &__buttons {
    display: flex;
    border-radius: 50px;
    overflow: hidden;
  }

  &__button {
    all: unset;
    width: 100%;
    text-align: center;
    background-color: #F7FAF8;
    padding: 12px 10px 10px;
    cursor: pointer;
    transition: background-color 250ms;
    @media screen and (max-width: 440px) {
      padding: 6px 8px 5px;
    }

    &:hover {
      background-color: darken(#F7FAF8, 5%);
    }

    &_active {
      background-color: #126B30;
      color: #ffffff;
      cursor: default;

      &:hover {
        background-color: #126B30;
      }
    }
  }
}

.bottom-subscription {
  margin-bottom: 20px;

  &__text {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  &__buttons {
    display: flex;
    gap: 5px;
    @media screen and (max-width: 530px) {
      flex-wrap: wrap;
    }
  }

  &__button {
    display: inline-block;
    all: unset;
    flex-grow: 1;
    font-size: 12px !important;
    text-align: center;
    font-weight: 400;
    padding: 5px 15px;
    background-color: #F7FAF8;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 250ms;
    max-width: 100%;
    flex-basis: 25%;
    &:last-of-type {
      flex-basis: auto;
      @media screen and (max-width: 530px) {
        flex-basis: 25%;
      }
    }

    &:hover {
      background-color: darken(#F7FAF8, 5%);
    }

    &_active {
      background-color: #126B30;
      color: #ffffff;
      cursor: default;

      &:hover {
        background-color: #126B30;
      }
    }
  }
}

.empty-cart {
  height: 50vh;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 30px 15px;
  font-size: 3rem;
  text-align: center;
  color: #16582D;
  @media screen and (max-width: 1280px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 374px) {
    font-size: 1.8rem;
  }
}
</style>