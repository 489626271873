import {defineStore} from 'pinia';
import {getData, postData} from '@/use/api';

export const useUserStore = defineStore({
    id: 'user',

    state: () => ({
        id: null,
        name: '',
        email: '',
        phoneNumber: '',
        avatar: '',
        addresses: [],
        temporaryAddress: null,
        activated: null,
    }),

    getters: {
        isAuth: state => {
            return !!state.id;
        },
        hasTemporaryAddress: state => !!state.temporaryAddress,
        hasAvatar: state => state.avatar.length,
        userAvatarPath: state => `${process.env.VUE_APP_BASE_URL}${state.avatar}`,
    },

    actions: {
        async logIn(email, password) {
            const response = await postData('/auth/login', {
                email: email,
                password: password,
            });
            if (!response.error) {
                localStorage.setItem('token', response.token);
                await this.getUserData();
                return response;
            } else {
                return {error: response.error.errorStatus === 401 ? 'Invalid login or password' : 'Error'};
            }
        },

        setUserData(userData) {
            this.id = userData.id;
            this.name = userData.name;
            this.phoneNumber = userData.phoneNumber;
            this.email = userData.email;
            this.avatar = userData?.image?.link ? userData.image.link : '';
            this.activated = userData.activated;
        },

        async getUserData() {
            const userDataResponse = await getData('/users');
            if (!userDataResponse.error) {
                this.setUserData(userDataResponse);
            }
            const userAddressesResponse = await getData('/addresses');
            if (!userAddressesResponse.error && userAddressesResponse.total > 0) {
                this.addresses = [...userAddressesResponse.items];
            }
            if (userDataResponse.error) throw new Error('error');
        },

        async addNewAddress(newAddressData) {
            const response = await postData('/addresses', newAddressData);
            if (!response.error) {
                this.addresses.unshift(response);
                return response;
            }
            if (response.error) return response;
        },

        async setTemporaryAddress(newAddressData) {
            if (newAddressData !== null) {
                this.temporaryAddress = {...newAddressData};
            } else {
                this.temporaryAddress = null;
            }
        },

        showAddressById(id) {
            let addressId = this.addresses.findIndex(item => item.id === id);
            if (addressId !== -1) return this.addresses[this.addresses.findIndex(item => item.id === id)];
        },

        setUserId(id) {
            this.id = id;
        },

        clearUserData() {
            this.id = null;
            this.name = '';
            this.email = '';
            this.phoneNumber = '';
            this.avatar = '';
            this.addresses = [];
            this.temporaryAddress = null;
            this.activated = null;
            localStorage.removeItem('token');
        }
    },
});