import {defineStore} from 'pinia';
import {getData, postData} from '@/use/api';
import {useCartStore} from '@/stores/cart';
import {useUserStore} from '@/stores/user';
import {getCookie} from '@/use/cookies';

export const useOrderStore = defineStore({
    id: 'order',

    state: () => ({
        userStore: useUserStore(),
        cartStore: useCartStore(),
        email: '',
        name: '',
        phoneNumber: '',
        city: '',
        street: '',
        number: '',
        appSuitUnitFloor: '',
        zip: '',
        paymentMethod: '',
        addressId: '',
        orderId: '',
        orderNumber: '',
        IBAN: '',
        BIC: '',
        orderSum: 0,
        discount: 0,
    }),

    getters: {
        hasDiscount: state => !!state.discount,
        orderFinalSum: state => state.discount ? state.orderSum - state.discount : state.orderSum,
    },

    actions: {
        setEmail(email) {
            this.email = email;
        },

        async getOrderCount() {
            const response = await getData('/orders/count');
            return response.count;
        },

        setDiscount(newValue) {
            this.discount = newValue;
        },

        setAddressId(id) {
            this.addressId = id;
        },

        setOrderData(newData) {
            if (this.userStore.isAuth) {
                this.name = newData.name;
                this.phoneNumber = newData.phoneNumber;
                if (this.userStore.hasTemporaryAddress) {
                    this.city = this.userStore.temporaryAddress.city;
                    this.street = this.userStore.temporaryAddress.street;
                    this.zip = this.userStore.temporaryAddress.zip;
                    this.number = this.userStore.temporaryAddress.number;
                    this.appSuitUnitFloor = this.userStore.temporaryAddress.appSuitUnitFloor;
                } else {
                    this.addressId = newData.addressId;
                    const addr = this.userStore.showAddressById(newData.addressId);
                    this.city = addr.city;
                    this.street = addr.street;
                    this.zip = addr.zip;
                    this.number = addr.number;
                    this.appSuitUnitFloor = addr.appSuitUnitFloor;
                }
            } else {
                this.name = newData.name;
                this.phoneNumber = newData.phoneNumber;
                this.city = newData.city;
                this.street = newData.street;
                this.number = newData.number;
                this.appSuitUnitFloor = newData.appSuitUnitFloor;
                this.zip = newData.zip;
            }
        },

        async createOrder(payload) {
            const orderUrl = this.userStore.isAuth ? '/orders' : '/public-orders';
            let orderObject;
            if (await getCookie('orderObject')) {
                orderObject = JSON.parse(await getCookie('orderObject'));
            } else {
                orderObject = {};
            }
            let addr;
            if (!this.userStore.isAuth || (this.userStore.isAuth && this.userStore.hasTemporaryAddress)) {
                addr = {
                    address: {
                        city: this.city,
                        street: this.street,
                        number: this.number,
                        appSuitUnitFloor: this.appSuitUnitFloor,
                        zip: this.zip,
                    },
                };
            }

            if (this.userStore.isAuth && !this.userStore.hasTemporaryAddress) addr = {addressId: this.addressId};

            let userId = !this.userStore.isAuth && this.userStore.id ? {userId: this.userStore.id} : '';

            const subscription = this.cartStore.subscription > 0 ? {subscription: this.cartStore.subscription} : '';

            const response = await postData(orderUrl, {
                email: this.email,
                name: this.name,
                phoneNumber: this.phoneNumber,
                ...payload,
                ...addr,
                ...userId,
                ...subscription,
                orderProducts: this.cartStore.cart.map(product => {
                    return {
                        productId: product.id,
                        presetId: product.presets[0].id,
                        amount: +product.quantity,
                    };
                }),
                trackingData: orderObject,
            });

            if (!response.error) {
                this.orderNumber = response.number;
                this.orderSum = (+response.totalPrice).toFixed(2);
                await this.userStore.setTemporaryAddress(null);
                // this.cartStore.clearCart();
                // this.clearOrder();
            }
            if (!response.error) return {linkCheckout: response['linkCheckout']};
            if (response.error) return response.error;
        },

        async getBankDetails() {
            const response = await getData('/payments/bank-details');
            if (!response.error) {
                this.IBAN = response.IBAN;
                this.BIC = response.BIC;
            }
        },

        clearOrder() {
            this.email = '';
            this.name = '';
            this.phoneNumber = '';
            this.city = '';
            this.street = '';
            this.number = '';
            this.appSuitUnitFloor = '';
            this.zip = '';
            this.paymentMethod = '';
            this.addressId = '';
            this.orderId = '';
            this.orderNumber = '';
            this.discount = 0;
            this.IBAN = '';
            this.BIC = '';
            this.orderSum = 0;
        }
    },
});