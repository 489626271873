<template>
  <button @click="cartStore['openCloseCartModal']">
    <img src="@/assets/icon/cart.svg" alt="cart">
    <span>
      {{ cartStore['numberOfProductsInTheCart'] }}
    </span>
  </button>
</template>

<script setup>
import { useCartStore } from '@/stores/cart';

const cartStore = useCartStore()

</script>

<style lang="scss" scoped>
button {
  span {
    font-size: 2.6rem;
    margin-left: 7px;
    padding-top: 6px;
    color: #000;
  }
  @media screen and (max-width: 767px) {
    img {
      width: 30px;
    }
    span {
      font-size: 2.2rem;
      margin-left: 4px;
      padding-top: 6px;
    }
  }
}
</style>