<template>
  <div class="modal">
    <transition name="transition">
      <div v-if="visible" @click="close" class="modal__backdrop"></div>
    </transition>

    <transition name="slide">
      <div v-if="visible" class="modal__body">
        <div class="modal__header">
          <div class="modal__title">
            Deine Bestellung
          </div>
          <button @click="close" class="modal__close-button">
            <img src="@/assets/icon/close.svg" alt="close">
          </button>
        </div>
        <div class="modal__content">
          <slot/>
        </div>
      </div>
    </transition>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const emits = defineEmits({
  closeModal: null,
});

const visible = ref(false);

onMounted(() => visible.value = true);

const close = () => {
  visible.value = false;
  setTimeout(() => {
    emits('closeModal');
  }, 350);
};

</script>

<style lang="scss" scoped>

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  z-index: 99999;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
  }

  &__close-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  &__body {
    background-color: #fff;
    height: 100%;
    min-height: 100vh;
    width: 665px;
    max-width: 100%;
    padding: 68px 75px;
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 4rem;
    color: #060606;
  }

  &__content {
    padding-top: 61px;
    @media screen and (max-width: 450px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

  }
  @media screen and (max-width: 767px) {
    &__body {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px;
    }
    &__title {
      font-size: 32px;
      text-transform: capitalize;
    }
    &__close-button {
      width: 22px;
      height: 22px;
      img {
        width: 100%;
      }
    }
  }
}

.transition {

  &-enter-active,
  &-leave-active {
    transition: all .35s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}


.slide {

  &-enter-active,
  &-leave-active {
    transition: all .35s ease;
  }

  &-enter-from,
  &-leave-to {
    transform: translateX(100%);
  }

}

</style>