<template>
  <transition name="modal">
    <ul @click="closeMenu" v-show="props.open" class="menu">
      <teleport to="body">
        <div @click.self="emits('closeMenu')" v-if="props.open" class="bd"></div>
      </teleport>
      <li class="menu__item">
        <router-link :to="{name: 'home'}" class="menu__link">VETOMOL</router-link>
      </li>
      <li class="menu__item">
        <a @click.prevent="goToVetohome" href="#" class="menu__link">VETOHOME</a>
      </li>
      <li class="menu__item">
        <router-link :to="{name: 'about'}" class="menu__link">ÜBER UNS</router-link>
      </li>
      <li class="menu__item">
        <router-link :to="{name: 'faq'}" class="menu__link">FAQ</router-link>
      </li>
    </ul>
  </transition>
</template>

<script setup>
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['closeMenu']);

const closeMenu = (e) => {
  if (e.target.classList.contains('menu__link')) emits('closeMenu');
};

const goToVetohome = () => {
  window.location.href = process.env.VUE_APP_CLIENT_BASE_URL;
};
</script>

<style lang="scss" scoped>
.bd {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.menu {
  margin: 0;
  border: 2px solid #707070;
  width: 100%;
  max-width: 280px;
  padding: 20px 0;
  list-style: none;
  font-size: 1.8rem;
  background-color: #fff;

  &__item {
    //border-bottom: 2px solid #707070;
  }

  &__link {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #000000;
    transition: color .35s, background-color .35s;
    text-transform: uppercase;

    &:hover {
      background-color: #126B30;
      color: #ffffff;
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity .35s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>