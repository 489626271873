import {defineStore} from 'pinia';
import {useOrderStore} from '@/stores/order';

export const useCartStore = defineStore({
    id: 'cart',

    state: () => ({
        cartModalIsOpened: false,
        cart: [],
        subscription: 0,
        orderStore: useOrderStore(),
        isSubscriptionActive: false,
    }),

    getters: {
        productPrice: state => {
            if (!state.cart.length) return 0
            return state.subscription === 0 ? state.cart[0].presets[0].price : state.cart[0].presets[0][`price${state.subscription}`];
        },
        subtotal: state => {
            if (!state.cart.length) return 0;
            const productPrice = state.subscription === 0 ? state.cart[0].presets[0].price : state.cart[0].presets[0][`price${state.subscription}`];
            const currentSum = state.cart[0].quantity * productPrice;
            const finalSum = (state.subscription > 0 ? (currentSum * state.subscription) : currentSum) - state.orderStore.discount;
            return finalSum.toFixed(2);
        },

        fullSum: state => {
            if (!state.cart[0]) return 0;
            return (state.cart[0].quantity * state.cart[0].presets[0].price * state.subscription).toFixed(2);
        },

        numberOfProductsInTheCart: state => state.cart[0]?.quantity || 0,

        productImageUrl: state => index => `${process.env.VUE_APP_BASE_URL}${state.cart[index].photos[0].link}`,
    },

    actions: {
        openCloseCartModal() {
            this.cartModalIsOpened = !this.cartModalIsOpened;
        },

        setSubscription(value) {
            this.isSubscriptionActive = value;
        },

        setSubscriptionPeriod(num) {
            this.subscription = +num;
        },

        updateSubscriptionPeriod(e) {
            this.subscription = +e.target.dataset.months;
        },

        addProductToCart(product) {
            if (this.cart.length < 1) {
                product.quantity = 1;
                this.cart.push(product);
            }

            // const newProduct = {
            //     id: product.id,
            //     name: product.name,
            //     price: product.presets[0].price,
            //     size: product.presets[0].size,
            //     photos: product.photos,
            //     quantity: 1,
            //     presetId: product.presets[0].id,
            // };
            //
            // if (this.cart.length) {
            //     let isProductExist = false;
            //
            //     this.cart.map(item => {
            //         if (item.id === product.id) {
            //             if (item.quantity < 1) item.quantity++;
            //             isProductExist = true;
            //         }
            //     });
            //
            //     if (!isProductExist) {
            //         this.cart.push(newProduct);
            //     }
            //
            // } else {
            //     this.cart.push(newProduct);
            // }
        },

        setProductQuantity(index, newValue) {
            this.cart[index].quantity = +newValue;
        },

        deleteProductFromCart(index) {
            this.cart.splice(index, 1);
        },

        clearCart() {
            this.cart = [];
            this.subscription = 0;
            this.isSubscriptionActive = false;
        },

    },
});