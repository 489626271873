<template>
  <swiper
    navigation
    :loop="true"
    :pagination="{clickable: true}"
    :modules="modules">
    <swiper-slide v-for="ingredient in ingredients" :key="ingredient.name">
      <div class="slide">
        <div class="slide__img">
          <span>{{ ingredient.name }}</span>
          <img :src="`/img/ingredients/${ingredient.image}`" alt="image">
        </div>
        <div class="slide__description">
          <p>{{ ingredient.description }}</p>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import { Navigation } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/navigation';

const modules = [Navigation, Pagination];

const ingredients = [
  {
    name: 'Brennnessel',
    description: 'Eine "brennende Umgebung", um darin Stöckchen zu suchen. Dafür unter anderem gut für die Beweglichkeit und den Wasserhaushalt.',
    image: 'nettle.svg',
  },
  {
    name: 'Karotte',
    description: 'Karotten haben nicht nur die Form eines leckeren Knochens, sondern die enthaltenen Ballaststoffe unterstützen die Verdauung deines Vierbeiners. Das enthaltende Vitamin A unterstützt zusätzlich die Sehkraft deines Hundes auf eine essenzielle Art und Weise.',
    image: 'carrot.svg',
  },
  {
    name: 'Spinat',
    description: 'Hast du schon mal Spinat ein zweites Mal warm gemacht? Schmeckt komisch, oder? Das liegt am hohen\n' +
      'Eisengehalt. Das wiederum ist super für die Blutbildung.\n',
    image: 'spinach.svg',
  },
  {
    name: 'Hagebutte',
    description: 'Über Felder, durch dein Büro, Flüsse, Wälder und leider auch in die ein oder andere\n' +
      'Schlammpfütze wuselt, rennt und suhlt sich dein Vierbeiner. Dabei kommen die Gelenke zum Einsatz. Die\n' +
      'Hagebutte hilft Gelenkbeschwerden vorzubeugen. Diese kleinen roten Supergranaten enthalten sekundäre\n' +
      'Pflanzenstoffe und Vitamine, damit Beschleunigung, Richtungswechsel und abruptes Anhalten auch noch im\n' +
      'hohen Alter funktionieren.\n',
    image: 'rose-hip.svg',
  },
  {
    name: 'Sellerie',
    description: 'Wächst nicht nur in Omas Garten, sondern gehört auch in jede Suppe. Das enthaltene Calcium ist gut für die Gelenke und jeden der über 200 Knochen deines Lieblings!',
    image: 'celery.svg',
  },
  {
    name: 'Fenchel',
    description: 'Time for Tea? Warum musste man das als Kind immer trinken, obwohl es nicht geschmeckt hat?\n' +
      'Richtig! Ist gut für den Magen! Die etherischen Öle beruhigen und entspannen den Verdauungstrakt und\n' +
      'deinem Hund geht’s besser!\n',
    image: 'fennel.svg',
  },
  {
    name: 'Blaubeere',
    description: 'Ein richtiger Vitamin C Hammer! Die enthaltenen Antioxidantien helfen beim Kampf gegen freie Radikale und schützen so die Zellen deiner Fellnase. Weniger Entzündungen und damit eine höhere Lebenserwartung können die Folge sein.',
    image: 'blue-berry.svg',
  },
  {
    name: 'Rote Beete',
    description: 'Love it or hate it! Zu rote Beete kann man stehen wie man möchte. Was aber klar ist, sie enthält wichtige Vitamine (z.B. Vitamin B6), die das Nerven- und Immunsystem deines Lieblingsvierbeiners unterstützen. Die enthaltene Folsäure ist gut für die Blutbildung und Stickstoffverbindungen können den Blutdruck positiv beeinflussen.',
    image: 'beetroot.svg',
  },
];
</script>

<style lang="scss" scoped>
.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__img {
    position: relative;
    max-width: 100%;
    width: 100%;
    text-align: center;

    & > span {
      position: absolute;
      font-weight: 700;
      font-size: 15rem;
      letter-spacing: 0.02em;
      color: rgba(125, 166, 156, 0.3);
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 100%;
    }

    img {
      max-width: 100%;
    }
  }

  &__description {
    padding-top: 20px;

    p {
      font-size: 18px;
      line-height: 1.69;
      text-align: center;
      color: #434343;
      max-width: 592px;
    }
  }

  @media screen and (max-width: 1200px) {
    &__img {
      & > span {
        font-size: 12rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &__img {
      img {
        height: 200px;
      }

      & > span {
        font-size: 14.645vw;
      }
    }
    &__description {
      padding-top: 0;

      p {
        margin: 16px 0 0;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

</style>