import {createRouter, createWebHistory} from 'vue-router';
import HomeView from '@/views/HomePage.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: 'HOME - VETOMOL',
            description: 'vetomol home page',
        },
    },
    {
        path: '/veggie-shot',
        name: 'veggie-shot',
        component: () => import('@/views/VeggieShotPage.vue'),
        meta: {
            title: 'HOME - VETOMOL',
            description: 'vetomol home page',
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutUsPage.vue'),
        meta: {
            title: 'About - VETOMOL',
            description: 'vetomol about page',
        },
    },
    {
        path: '/log-in',
        name: 'logIn',
        component: () => import('@/views/LogInPage'),
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('@/views/CheckoutPage.vue'),
        meta: {
            title: 'CHECKOUT - VETOMOL',
            description: 'vetomol checkout page',
        },
    },
    {
        path: '/thanks',
        name: 'thanks',
        component: () => import('@/views/ThanksPage.vue'),
        meta: {
            title: 'THANKS - VETOMOL',
            description: 'vetomol thanks page',
        },
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('@/views/InvoicePage'),
        meta: {
            title: 'INVOICE - VETOMOL',
            description: 'vetomol invoice page',
        },
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: () => import('@/views/ImpressumPage'),
        meta: {
            title: 'IMPRESSUM - VETOMOL',
            description: 'vetomol impressum page',
        },
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/PrivacyPage'),
        meta: {
            title: 'PRIVACY - VETOMOL',
            description: 'vetomol privacy page',
        },
    },
    {
        path: '/agb',
        name: 'agb',
        component: () => import('@/views/AgbPage'),
        meta: {
            title: 'AGB - VETOMOL',
            description: 'vetomol agb page',
        },
    },
    {
        path: '/return-item',
        name: 'return-item',
        component: () => import('@/views/ReturnItemPage'),
        meta: {
            title: 'Artikel zurücksenden - VETOMOL',
            description: 'Artikel zurücksenden',
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/views/FaqPage.vue'),
        meta: {
            title: 'FAQ - VETOMOL',
            description: 'FAQ',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('@/views/ErrorPage.vue'),
        meta: {
            title: 'ERROR PAGE - VETOMOL',
            description: 'vetomol error page',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    },
});

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'vetomol';
    }

    if (to.meta && to.meta.description) {
        const tag = document.createElement('meta');
        tag.name = 'description';
        tag.content = to.meta.description;
        document.head.appendChild(tag);
    }

    next();
});

// ...

export default router;
