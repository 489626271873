<template>
  <header class="header">
    <div class="container">
      <div class="button-mobile">
        <cart-button/>
      </div>
      <div class="logo">
        <router-link :to="{name: 'home'}">
          <img src="@/assets/img/logo.svg" alt="logo">
        </router-link>
      </div>
      <div class="buttons">
        <cart-button/>
        <app-user-profile-button/>
        <button @click="toggleMenu" class="menu-button">
          <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="2" rx="1" fill="currentColor"/>
            <rect y="9" width="25" height="2" rx="1" fill="currentColor"/>
            <rect y="18" width="25" height="2" rx="1" fill="currentColor"/>
          </svg>
        </button>
      </div>
      <app-header-menu :open="isMenuOpen" @closeMenu="toggleMenu" class="header__menu"/>
    </div>
  </header>
</template>

<script setup>
import CartButton from '@/components/cart/CartButton';
import { ref } from 'vue';
import AppHeaderMenu from '@/components/UI/AppHeaderMenu.vue';
import AppUserProfileButton from '@/components/user/AppUserProfileButton.vue';

const isMenuOpen = ref(false)

const toggleMenu = () => isMenuOpen.value = !isMenuOpen.value;
</script>

<style lang="scss" scoped>

.container {
  display: flex;
  justify-content: space-between;
  border-left: 2px solid #808080;
  position: relative;
}

.header {
  border-bottom: 2px solid #808080;
  padding: 0 20px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 999;

  &__menu {
    position: absolute;
    top: 100%;
    right: -2px;
  }

  .logo {
    padding-left: 15px;
    padding-bottom: 24px;
    padding-top: 22px;
  }

  .buttons, .button-mobile {
    display: flex;

    button {
      height: 100%;
      border-left: 2px solid #808080;
      border-right: 2px solid #808080;
      border-top: none;
      border-bottom: none;
      background: none;
      width: 12rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -2px;

      span {
        padding-top: 8px;
      }

      &.menu-button, &.profile-button {
        transition: color .35s, background-color .35s;
        color: #000;
        &:hover {
          color: #fff;
          background-color: #126B30;
        }
      }
    }
  }

  .button-mobile {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .container {
      padding: 0;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px;
    .button-mobile {
      display: flex;
      align-items: center;
      margin-left: -2px;
    }
    .buttons {
      button {
        &:last-child {
          border-left: 2px solid #808080;
          border-right: 2px solid #808080;
        }

        &:first-child {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .logo {
      padding: 16px 14px 12px;

      img {
        width: 110px;
      }
    }
    .buttons button,
    .button-mobile button {
      padding: 0;
      width: 6rem;

      span {
        font-size: 2.2rem;
      }

      img {
        width: 30px;
      }
    }
  }

  @media screen and (max-width: 374px) {
    .logo {
      img {
        max-width: 80px;
      }
    }
  }
}

</style>